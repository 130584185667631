import React, { FC } from 'react';

type DebugComponentProps = {
  typeName: string;
};

const DebugComponent: FC<DebugComponentProps> = ({ typeName, ...componentProps }) => (
  <div style={{ border: '1px solid red', padding: '5px' }}>
    <h2>Missing component for type: {typeName}</h2>
    <p>
      Check your <code>typesMapper</code> in <code>BodyRenderer</code> component
    </p>
    <h3>Component&apos;s props:</h3>
    <pre style={{ overflow: 'hidden' }}>{JSON.stringify(componentProps, null, 2)}</pre>
  </div>
);

export default DebugComponent;
